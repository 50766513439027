.navbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 2rem;
	position: relative;
	z-index: 500;
}

.navbar-links {
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
}

.navbar-links_logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.navbar-links_logo img {
	height: 100px;
	width: 170px;
}

.navbar-signup {
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar-signup p {
	margin-right: 10px;
	color: var(--color-white);
}

.navbar-signup button {
	padding: 0.3rem 1rem;
	color: var(--color-black);
}

ul li {
	margin: 0 1rem;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	list-style: none;
}

.navbar-links_container ul li {
	color: var(--color-white);
	font-family: var(--font-family);
	font-weight: 500;
	font-size: 16px;
	line-height: 25px;
	text-transform: capitalize;
	text-decoration: none;
}

.navbar-links_container ul li:hover {
	color: var(--color-golden);
}
.navbar-links_container ul li.active{
	color: var(--color-golden);
}

.navbar-menu {
	display: none;
}

.navbar-menu_container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: var(--color-black);
	transition: 0.5s ease;

	flex-direction: column;
	z-index: 5;
}

.navbar-menu_container .container__close {
	font-size: 27px;
	color: var(--color-golden);
	cursor: pointer;
	transition: 0.5s ease;

	position: absolute;
	top: 20px;
	right: 20px;
}

.navbar-menu_container-links {
	margin: 10rem;
	list-style: none;
}

.navbar-menu-links {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.navbar-menu-links li {
	margin-bottom: 2rem;
	cursor: pointer;
	color: var(--color-white);
	font-size: 2rem;
	text-align: center;
	font-family: var(--font-family);
}

.navbar-menu_container ul li a:hover {
	color: var(--color-white);
	transition: o.5s ease-in;
}

.navbar-links_container ul li a:hover {
	color: var(--color-golden);
}

.navbar-menu_container-links-signup {
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbar-menu_container-links-signup p {
	color: var(--color-white);
}

.navbar-menu_container-links-signup button {
	margin-left: 10px;
}

@media screen and(min-width: 2000px) {
	.navbar-links_logo img {
		width: 210px;
	}
	.navbar-menu_container-links-signup {
		display: none;
	}
}

@media screen and (max-width: 1150px) {
	.navbar-menu {
		display: none;
	}
}

@media screen and (max-width: 770px) {
	.navbar-links {
		display: none;
	}
	.navbar {
		padding: 1rem;
	}

	.navbar-signup {
		display: flex;
	}

	.navbar-menu {
		display: flex;
	}

	.navbar-menu-links li {
		font-size: 1.5rem;
	}
	.navbar-links_logo img {
		height: 100px;
		width: 170px;
	}
	.navbar-menu_container-links-signup {
		display: none;
	}
}

@media screen and (max-width: 450px) {
	.navbar-signup {
		display: none;
	}
	.navbar-links_logo img {
		height: 80px;
		width: 150px;
	}
	.navbar-menu_container-links-signup {
		display: flex;
	}
}
