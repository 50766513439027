.header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.header-content {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 10;
}

.header-text {
	margin-right: 3rem;
}

.header-img {
	width: 100%;
	z-index: 400;
	margin-left: 3rem;
}

.header-text h1 {
	font-family: var(--font-family);
	color: var(--color-white);
	font-size: 32px;
	font-weight: 700;
	line-height: 60px;
	margin-bottom: 2rem;
}

.header-text p {
	font-family: var(--font-family);
	color: var(--color-white);
	font-size: 18px;
	font-weight: 500;
	line-height: 25px;
	margin-bottom: 3rem;
}

.header-img img {
	width: 450px;
}

.header-crypto {
	margin-top: 11rem;
	display: none;
	justify-content: center;
	align-items: center;
	padding: 30px 120px;
	border-radius: 20px;
	border: 3px solid #ffd700;
	box-shadow: 0px 0px 20px 0px #ffd700;
	background: var(--color-gray);
	position: relative;
	z-index: 400;
}

.crypto {
	margin: 0 2rem;
}

.crypto img {
	width: 50px;
}

.top-ellipse {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	overflow: hidden;
}

.top-ellipse img {
	width: 850px;
	height: 800px;
}

@media screen and (max-width: 1025px) {
	.header-text h1 {
		font-size: 20px;
		font-weight: 600;
		line-height: 35px;
	}

	.header-img img {
		width: 400px;
	}

	.section__padding {
		padding: 2rem 5rem;
	}
	.top-ellipse img {
		width: 700px;
		height: 650px;
	}
}

@media screen and (max-width: 820px) {
	.header-text h1 {
		font-size: 15px;
		font-weight: 500;
		line-height: 28px;
		margin-bottom: 0.5rem;
	}

	.header-img img {
		width: 250px;
		z-index: 100;
	}

	.header-text p {
		font-size: 14px;
		font-weight: 300;
		line-height: 20px;
		margin-bottom: 1rem;
	}

	.header-crypto {
		padding: 15px 60px;
	}

	.crypto {
		margin: 0 1rem;
	}

	.crypto img {
		width: 30px;
	}

	.section__padding {
		padding: 1rem 4rem 2rem;
	}
	.top-ellipse img {
		width: 500px;
		height: 450px;
	}
}

@media screen and (max-width: 540px) {
	.header-content {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.header-text {
		margin-right: 0rem;
	}
	.section__padding {
		padding: 0 2rem;
	}
	.header-img {
		margin-left: 6rem;
		z-index: 100;
		margin-bottom: 2rem;
		margin-top: 2rem;
	}
	.header-crypto {
		padding: 15px 40px;
		margin-top: 3.5rem;
	}
	.crypto {
		margin: 0 0.5rem;
	}
	.crypto img {
		width: 30px;
	}
	.top-ellipse img {
		width: 300px;
		height: 300px;
	}
	.button {
		font-size: 12px;
	}
}
