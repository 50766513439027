.features {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 100;
}

.feature-content {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: relative;
	z-index: 100;
}

.feature-key {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-right: 10rem;
	width: 40%;
}

.feature-details {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;
	width: 60%;
}

.feature-key h1 {
	font-family: var(--font-base);
	color: var(--color-white);
	font-size: 60px;
	font-weight: 500;
	margin-bottom: 1rem;
}

.feature-key p {
	font-family: var(--font-family);
	color: var(--color-white);
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	margin-bottom: 1rem;
}

.feature-key button {
	width: 180px;
}

.details h3 {
	font-family: var(--font-family);
	color: var(--color-white);
	font-size: 18px;
	font-weight: 600;
	line-height: 30px;
	margin-bottom: 1rem;
}

.details p {
	font-family: var(--font-family);
	color: var(--color-white);
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	text-align: left;
}

.middle-ellipse {
	z-index: 3;
	position: absolute;
	top: -50px;
	right: 0px;
}

.middle-ellipse img {
	width: 750px;
	height: 850px;
}

.signup {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 10;
}

.signup button {
	color: var(--color-white);
	margin: 5rem 0 1rem;
	padding: 15px 20px;
	border-radius: 36px;
	border: 1px solid #fff;
	font-size: 15px;
	background-color: rgba(0, 0, 0, 0.01);
	text-align: center;
}

.signup-motion_img img {
	width: 500px;
	margin-left: 5rem;
}

@media screen and (max-width: 1025px) {
	.feature-key {
		width: 33%;
		margin-right: 7rem;
	}
	.feature-details {
		width: 67%;
	}
	.feature-key h1 {
		font-size: 40px;
		margin-bottom: 0.75rem;
	}
	.feature-key p {
		font-size: 17px;
		line-height: 25px;
	}
	.signup button {
		width: 120px;
	}
	.details h3 {
		font-size: 15px;
		line-height: 25px;
		font-weight: 550;
		margin-bottom: 0.75rem;
	}
	.details p {
		font-size: 11px;
		line-height: 16px;
	}
	.signup {
		display: flex;
	}
	.middle-ellipse {
		top: 80px;
	}
	.middle-ellipse img {
		width: 600px;
		height: 750px;
	}
}

@media screen and (max-width: 770px) {
	.feature-key {
		width: 25%;
		margin-right: 5rem;
	}
	.feature-key h1 {
		font-size: 30px;
		margin-bottom: 0.5rem;
	}
	.feature-key p {
		font-size: 17px;
		line-height: 19px;
	}
	.details h3 {
		font-size: 13px;
		line-height: 20px;
		font-weight: 450;
		margin-bottom: 0.5rem;
	}
	.details p {
		font-size: 10px;
		line-height: 14px;
	}
	.middle-ellipse img {
		width: 450px;
		height: 550px;
	}
}

@media screen and (max-width: 580px) {
	.feature-content {
		display: grid;
		grid-template-columns: 1fr;
		justify-content: center;
		align-items: center;
	}
	.feature-key {
		width: 100%;
		margin: 2rem 0;
		justify-content: center;
		align-items: center;
	}
	.feature-key p {
		text-align: center;
	}
	.feature-details {
		width: 100%;
	}
	.signup-motion_img img {
		width: 250px;
		margin-left: 0;
	}
	.details h3 {
		text-align: center;
	}
	.details p {
		text-align: center;
	}
	.middle-ellipse img {
		width: 280px;
		height: 330px;
	}
	.middle-ellipse{
		top: 50px;
	}
	.signup button {
		padding: 13px 15px;
		font-size: 13px;
	}
}
