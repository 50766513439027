@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;
  --font-base: "Cormorant Upright", serif;

  --color-golden: #ffd700;
  --color-black: #1a1a1a;
  --color-black-das: #1e1e1e;
  --color-gray: #ffd7000d;
  --color-crimson: #f5efdb;
  --color-grey: #aaaaaa;
  --color-grey-second: #aaaaaa;
  --color-white: #ffffff;
  --footer-bg: #6d5c06;
  --secondary-bg: #ffd700;
}

.dark-theme {
  --color-white: #1a1a1a;
  --color-black: #ffffff;
  --color-black-das: #f9f9f9;
  --color-grey: #313131;
  --color-grey-second: #ececec;
}
