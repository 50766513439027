.aboutus {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutus-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.aboutus-header {
  margin-bottom: 3rem;
  position: relative;
  z-index: 10;
}

.aboutus-header h1 {
  color: var(--color-white);
  font-family: var(--font-family);
  margin-bottom: 1rem;
  text-align: center;
  font-size: 60px;
  font-weight: 500;
  line-height: 70px;
  text-align: center;
}

.aboutus-header p {
  color: var(--color-white);
  font-family: var(--font-family);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}

.aboutus-vision {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 5.3rem;
}

.vision {
  padding: 10px;
  background-color: #000;
  border-radius: 10px;
  margin: 0 1.5rem;
  z-index: 10;
}

.vision h3 {
  color: #fff;
  font-family: var(--font-family);
  padding: 5px;
  margin-bottom: 0.5rem;
  font-size: 20px;
  font-weight: 600;
}

.vision p {
  font-family: var(--font-family);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding: 5px;
  margin-bottom: 0.5rem;
}

.vision a {
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
  padding: 5px;
  margin-bottom: 0.3rem;
}

.vision img {
  margin-left: 0.5rem;
  width: 20px;
  transition: transform 0.3s ease;
}

.vision a:hover img {
  transform: translateX(10px);
}

.chooseus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  z-index: 100;
}

.chooseus-img {
  margin-top: 3rem;
  width: 60%;
}

.chooseus-img img {
  width: 500px;
}

.chooseus-content {
  width: 40%;
}

.chooseus-content h1 {
  font-family: var(--font-base);
  color: var(--color-white);
  font-size: 60px;
  font-weight: 500;
  line-height: 80px;
  text-align: left;
  margin-bottom: 2rem;
}

.chooseus-content p {
  font-family: var(--font-family);
  color: var(--color-white);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  margin-bottom: 2rem;
}

.chooseus-content button {
  margin-bottom: 2rem;
}

.chooseus-content li {
  font-family: var(--font-family);
  color: var(--color-white);
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
  margin: 1rem 0;
}

.chooseus-content img {
  width: 15px;
  margin-right: 0.5rem;
  animation: bounce 0.3s ease;
  transform: translateY(0);
}

.chooseus-content li:hover img {
  transform: translateY(-10px);
}

.middle-ellipse-2 {
  display: none;
  z-index: 1;
  position: absolute;
  right: 0px;
}

@media screen and (max-width: 1110px) {
  .vision {
    margin: 0 1rem;
  }
  .vision h3 {
    margin-bottom: 0.25rem;
  }
  .vision p {
    margin-bottom: 0.25rem;
  }
  .chooseus-content h1 {
    font-size: 50px;
    margin-bottom: 1rem;
  }
  .chooseus-content p {
    margin-bottom: 1rem;
  }
  .chooseus-content button {
    margin-bottom: 1rem;
  }
  .bottom-ellipse img {
    height: 700px;
    width: 600px;
  }
}

@media screen and (max-width: 990px) {
  .aboutus-header h1 {
    font-size: 45px;
    line-height: 60px;
  }
  .aboutus-header p {
    font-size: 15px;
  }
  .aboutus-vision {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .vision {
    margin: 0;
  }
  .chooseus-img img {
    width: 300px;
  }
  .chooseus-content h1 {
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 0.2rem;
  }
  .chooseus-content p {
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    margin-bottom: 0.7rem;
  }
  .chooseus-content button {
    margin-bottom: 0.5rem;
  }
  .chooseus-content li {
    font-size: 12px;
    font-weight: 400;
    margin: 0.5rem 0;
  }
  .bottom-ellipse img {
    height: 530px;
    width: 480px;
  }
}

@media screen and (max-width: 680px) {
  .aboutus-header h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .aboutus-header p {
    font-size: 12px;
    line-height: 23px;
  }
  .aboutus-vision {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .chooseus {
    flex-direction: column-reverse;
    width: 100%;
  }
  .chooseus-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .chooseus-img {
    display: none;
  }
  .chooseus-content p {
    text-align: center;
    margin-bottom: 1rem;
  }
  .chooseus-content li {
    margin-top: 0.75rem;
  }
  .bottom-ellipse img {
    height: 350px;
    width: 280px;
  }
  .middle-ellipse-2 {
    display: flex;
    top: 1000px;
  }
  .middle-ellipse-2 img {
    width: 280px;
    height: 330px;
  }
}
