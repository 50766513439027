.package {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 10;
}

.package-header h1 {
  color: var(--color-golden);
  font-family: var(--font-family);
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  margin-bottom: 1rem;
}

.package-header p {
  color: var(--color-white);
  font-family: var(--font-family);
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  margin-bottom: 2rem;
}

.package-plans {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10;
}

.plans {
  background-color: var(--color-golden);
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.plans p {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin: 1.5rem;
}

.plans h3 {
  font-family: var(--font-family);
}

.plans P:last-of-type {
  width: 100%;
  border-bottom: solid 5px;
  border-color: #000;
  text-align: center;
  padding-bottom: 1.5rem;
}

.plans h3 {
  margin: 1.5rem;
}

.plans li {
  font-family: var(--font-family);
  margin: 0.7rem;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}

.plans button {
  background-color: var(--color-black);
  color: var(--color-white);
  margin: 1.5rem;
  font-family: var(--font-family);
  font-weight: 600;
  letter-spacing: 0.04em;
  line-height: 25px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.plans button:hover {
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  background-color: var(--color-golden);
  color: var(--color-black);
}

@media screen and (max-width: 1025px) {
  .package-header h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
  }
  .package-header p {
    font-size: 17px;
    font-weight: 450;
    line-height: 25px;
  }
  .plans {
    padding: 10px;
  }
  .plans p {
    margin: 1rem;
  }

  .plans P:last-of-type {
    padding-bottom: 1rem;
  }

  .plans h3 {
    margin: 1rem;
  }

  .plans li {
    margin: 0.5rem;
  }
}

@media screen and (max-width: 770px) {
  .package-header h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
  }
  .package-header p {
    font-size: 17px;
    font-weight: 450;
    line-height: 25px;
  }
  .package-plans {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 430px) {
  .package {
    margin-top: 3rem;
  }
  .package-header h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 45px;
  }
  .package-header p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
  .package-plans {
    grid-template-columns: 1fr;
  }
}
